import * as Yup from "yup";

const stepOneSchema = Yup.object({
  name: Yup.string().required("Please enter name"),
  phoneNumber: Yup.string()
    .required("Please enter phone number")
    .min(10, "Too Short!")
    .max(10, "Too Long!"),
  designation: Yup.string().required("Please enter designation"),
});

const stepTwoSchema = Yup.object({
  questions: Yup.array().of(
    Yup.object({
      question: Yup.string().required("Please enter question"),
      answer: Yup.string().required("Please enter answer"),
      questionformat: Yup.string().required("Please enter question format"),
    })
  ),
  // phoneNumber: Yup.string().required('Please enter phone number'),
  // designation: Yup.string().required('Please enter designation'),
});
const xamProSchema = Yup.object({
  name: Yup.string().required("Please enter name"),
  email: Yup.string().email("Invalid email").required("Required"),
  message: Yup.string().required("Please enter designation"),
});
export { stepOneSchema, stepTwoSchema, xamProSchema };
