export const GET_USER_LIST = "GET_USER_LIST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILED = "GET_USER_LIST_FAILED";

export const GET_WINNER = "GET_WINNER";
export const GET_WINNER_SUCCESS = "GET_WINNER_SUCCESS";
export const GET_WINNER_FAILED = "GET_WINNER_FAILED";

export const SELECT_WINNER = "SELECT_WINNER"
export const SELECT_WINNER_SUCCESS = "SELECT_WINNER_SUCCESS"
export const SELECT_WINNER_FAIL = "SELECT_WINNER_FAIL"

export const RESET_WINNER = "RESET_WINNER"
export const RESET_WINNER_SUCCESS = "RESET_WINNER_SUCCESS"
export const RESET_WINNER_FAIL = "RESET_WINNER_FAIL"


export const STORE_RAFFLE_DATA = "STORE_RAFFLE_DATA";