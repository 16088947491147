import { call, put, takeEvery } from "redux-saga/effects";
import { deleteData, getData, postData } from "../../Dashboard/helpers/api_helper";
import { toaster } from "../../Dashboard/helpers/custom/toaster";

import { getUserListFail, getUserListSuccess, getWinnerList, getWinnerListFail, getWinnerListSuccess, resetWinnerFail, resetWinnerSuccess, selectWinnerFail, selectWinnerSuccess, storeRaffleData } from "./action";
import { GET_USER_LIST, GET_WINNER, RESET_WINNER, SELECT_WINNER } from "./actionTypes";



function* getUserList({ payload: { token } }) {
  try {
    const url = "/rafle-draw/find-user";
    const response = yield call(getData, url, token);
    yield put(getUserListSuccess(response));
  } catch (error) {
    const message = error?.response?.data?.message || "Get User failed";
    yield put(getUserListFail(message));
  }
}
function* getWinnerListData({ payload: { token } }) {
    try {
      const url = "/rafle-draw/get-winner-list";
      const response = yield call(getData, url, token);
      yield put(getWinnerListSuccess(response));
      yield put(storeRaffleData('raffleData', response?.getData));
    } catch (error) {
      const message = error?.response?.data?.message || "Get Winner failed";
      yield put(getWinnerListFail(message));
    }
  }
  function* workerSelectWinner({ payload: { token } }) {
    try {
      const url = `/rafle-draw/find-winner`;
      const response = yield call(getData, url, token);
    
      yield put(selectWinnerSuccess(response?.getData));
      yield put(getWinnerList());
     
     
    } catch (error) {
      const message = error?.response?.data?.message || `Set Winner fail`;
     
      yield put(selectWinnerFail(message));
    }
  }
  function* workerResetWinner({ payload: { token } }) {
    try {
      const url = `rafle-draw/reset-winner`;
      const response = yield call(deleteData, url, token);
      yield put(resetWinnerSuccess(response));
      toaster("success", `Reset Prize Successfully`);
      yield put(getWinnerList());
    } catch (error) {
      const message = error?.response?.data?.message || `Reset fail`;
     
      yield put(resetWinnerFail(message));
    }
  }
function* RaffleSaga() {

  yield takeEvery(GET_USER_LIST, getUserList);
  yield takeEvery(GET_WINNER, getWinnerListData);
  yield takeEvery(SELECT_WINNER, workerSelectWinner);
  yield takeEvery(RESET_WINNER, workerResetWinner);
}
export default RaffleSaga;
