import { Redirect } from "react-router-dom";
import Login from "../Components/Authentication/Login";
// const Dashboard = lazy(() => import("../Components/Dashboard/Dashboard"));
// const Login = lazy(() => import("../Components/Authentication/Login"));
import CentroDashboard from "../../Dashboard/Centro/pages/CentroDashboard/CentroDashboard";
import LandingPage from "../../Dashboard/Centro/pages/LandingPage/LandingPage";
import Categories from "../Centro/pages/Categories/Categories";
import EditHero from "../Centro/pages/Hero/EditHero";
import MenuEdit from "../Centro/pages/Menu/MenuEdit";
import AddProduct from "../Centro/pages/Products/AddProduct";
import Products from "../Centro/pages/Products/Products";
import Dashboard from "../Components/Dashboard/Dashboard";

const openRoute = [{ path: "/login", component: Login }];
const protectedRoute = [
  {
    path: "/dashboard",
    exact: true,
    component: () => <Redirect to="/admin/dashboard" />,
  },
  {
    path: "/admin/dashboard",
    component: Dashboard,
    exact: true,
  },
  {
    path: "/centro/centro-dashboard",
    component: CentroDashboard,
    exact: true,
  },
  {
    path: "/centro/landing-page",
    component: LandingPage,
    exact: true,
  },
  {
    path: "/centro/landing-page/edit/:id",
    component: MenuEdit,
    exact: true,
  },
  {
    path: "/centro/landing-page/edit-hero/:type",
    component: EditHero,
    exact: true,
  },
  {
    path: "/centro/landing-page/view-hero/:type",
    component: () => <EditHero view />,
    exact: true,
  },
  {
    path: "/centro/categories",
    component: Categories,
    exact: true,
  },
  {
    path: "/centro/products",
    component: Products,
    exact: true,
  },
  {
    path: "/centro/products/add-product",
    component: AddProduct,
    exact: true,
  },
  {
    path: "/centro/products/add-product/:id",
    component: () => <AddProduct edit />,
    exact: true,
  },
];

export { openRoute, protectedRoute };
